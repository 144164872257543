'use client'

import classnames from 'classnames'
import styles from './ContactForm.module.scss'
import ContactForm from '@/components/ContactForm/ContactForm'
import Widont from '@/components/Widont/Widont'
import useBreakpoint from '@/hooks/use-breakpoint'

const ContactFormSection = ({ className, subtitle, title }: SanityContactForm) => {
  const { isMobile } = useBreakpoint()

  if (!title) return null

  return (
    <div className={classnames(styles.ContactForm, className)}>
      <div className={classnames(styles.inner)}>
        <div className={styles.textContainer}>
          <h1 className={styles.title}>
            <Widont text={title} />
          </h1>
          <div>
            {subtitle && (
              <p className={styles.subtitle}>
                <Widont text={subtitle} />
              </p>
            )}
            {/* TODO: REMOVE THIS ONCE LOCATION VISITED LOGIC IS IMPROVED */}
            {process.env.NEXT_PUBLIC_IS_CANADA_WEBSITE !== 'true' && <p className={styles.subtitle}>
              <Widont text="Please be sure to enter a Location when you're writing to us about a Restaurant Experience." />
            </p>}
          </div>
        </div>
        <div className={styles.formContainer}>
          {process.env.NEXT_PUBLIC_IS_CANADA_WEBSITE === 'true' ? <iframe
            src="https://go.momos.com/sb7M1"
            style={{
              border: '0px #ffffff none',
              height: isMobile ? '685px' : '641px',
              width: '90%',
            }}
            scrolling="yes"
            frameBorder="1"
            allowFullScreen
                                                                  /> : <ContactForm />}
        </div>
      </div>
    </div>
  )
}

ContactFormSection.displayName = 'ContactFormSection'

export default ContactFormSection
